.popover {
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid #dee3eb;
	border-radius: 3px;
	-webkit-filter: drop-shadow(0 1px 3px $black-1);
	filter: drop-shadow(0 1px 3px $black-1);
	.popover-arrow {
		position: absolute;
		display: block;
		width: 0.5rem;
		height: 0.5rem;
		margin: 0 3px;
		margin-left: calc(.25rem + -5px);
		&::before, &::after {
			position: absolute;
			display: block;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}
.bs-popover-top, .bs-popover-auto[data-popper-placement^="top"] {
	margin-bottom: 0.5rem;
}
.bs-popover-top .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] .popover-arrow {
	bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before, .bs-popover-top .arrow::after, .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
	border-width: 0.5rem 0.25rem 0;
}
.bs-popover-top .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before {
	bottom: 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
	bottom: 1px;
	border-top-color: $white;
}
.bs-popover-end, .bs-popover-auto[data-popper-placement^="right"] {
	margin-left: 0.5rem;
}
.bs-popover-end .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
	left: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 0.5rem;
	margin: 3px 0;
}
.bs-popover-end .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before, .bs-popover-end .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
	border-width: 0.25rem 0.5rem 0.25rem 0;
}
.bs-popover-end .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
	left: 0;
	border-right-color: #dee3eb;
}
.bs-popover-end .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
	left: 1px;
	border-right-color: $white;
}
.bs-popover-bottom, .bs-popover-auto[data-popper-placement^="bottom"] {
	margin-top: 0.5rem;
}
.bs-popover-bottom .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow {
	top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before, .bs-popover-bottom .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
	border-width: 0 0.25rem 0.5rem 0.25rem;
}
.bs-popover-bottom .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
	top: 0;
	border-bottom-color: #dee3eb;
}
.bs-popover-bottom .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
	top: 1px;
	border-bottom-color: $white;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 0.5rem;
	margin-left: -0.25rem;
	content: "";
	border-bottom: 1px solid $white;
}
.bs-popover-start, .bs-popover-auto[data-popper-placement^="left"] {
	margin-right: 0.5rem;
}
.bs-popover-start .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
	right: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 0.5rem;
	margin: 3px 0;
}
.bs-popover-start .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before, .bs-popover-start .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
	border-width: 0.25rem 0 0.25rem 0.5rem;
}
.bs-popover-start .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
	right: 0;
	border-left-color: #dee3eb;
}
.bs-popover-start .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
	right: 1px;
	border-left-color: $white;
}
.popover-header {
	padding: 0.5rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.9375rem;
	color: inherit;
	background-color: $white;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	&:empty {
		display: none;
	}
}
.popover-body {
	padding: 0.75rem 1rem;
	color: #6e7687;
}
.popover {
	&.bs-popover-top, &.bs-popover-auto[data-popper-placement^="top"] {
		margin-bottom: 0.625rem;
	}
}
.popover-static-demo .popover {
	position: relative;
}