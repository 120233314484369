.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-19 {
    font-size: 19px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-23 {
    font-size: 23px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-40 {
    font-size: 40px;
}

.fs-50 {
    font-size: 50px;
}

.fs-100 {
    font-size: 100px;
}

.fw-light {
    font-weight: 300 !important;
}

.fw-normal {
    font-weight: 400 !important;
}

.fw-600 {
    font-weight: 600;
}

.fw-semibold {
    font-weight: 500 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.font-italic {
    font-style: italic !important;
}

.fs-70 {
    font-size: 70px !important;
}

.fs-8 {
    font-size: 8px !important;
}